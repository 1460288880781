@import "~bootstrap/dist/css/bootstrap.css";
@import "~bootstrap/dist/css/bootstrap.css";
@import "~@ng-select/ng-select/themes/default.theme.css";
@import '~@ctrl/ngx-emoji-mart/picker';

/*/ @import '~ngx-owl-carousel-o/lib/styles/scss/owl.carousel';
@import '~ngx-owl-carousel-o/lib/styles/scss/owl.theme.default'; */

/* -----------Not for copy--------- */

/* *,
*:after,
*:before {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}/ */

:root {
  --bg-warning: #ffcd04 !important;
  --bg-warning-rgba: 255, 205, 4 !important;
  --bg-danger: #b51c36 !important;
  --bg-maroon: #811719 !important;
  --bg-blue: #8caaeb !important;
  --bg-gray: #f5f5f5 !important;
  --bg-white: #fff !important;
  --bg-body: linear-gradient(120deg, #dedfe0, #dedfe0) !important;
}

html,
body {
  background: var(--bg-body);
  font-family: sans-serif !important;
  /* /font-size: 1rem !important; /*/
  font-weight: 400 !important;
  line-height: 1.5 !important;
  font-size: 100%;
  box-sizing: border-box !important;
  scroll-behavior: smooth !important;
}

.bg-body {
  background: linear-gradient(120deg, #dedfe0, #dedfe0) !important;
}

.bg-gray {
  background: #f5f5f5 !important;
}

.bg-warning {
  background: var(--bg-warning) !important;
}

.border-warning {
  border-color: var(--bg-warning) !important;
}

.text-warning {
  color: var(--bg-warning) !important;
}

.border-danger {
  border-color: var(--bg-danger) !important;
}

.border-dashed {
  border-style: dashed !important;
}

.text-danger {
  color: var(--bg-danger) !important;
}

.bg-danger {
  background: var(--bg-danger) !important;
}

.btn-outline-danger {
  color: var(--bg-danger) !important;
  border-color: var(--bg-danger) !important;
}

.btn-outline-danger:hover {
  color: var(--bg-white) !important;
  background-color: var(--bg-danger);
  border-color: var(--bg-danger);
}

.bg-maroon {
  background: var(--bg-maroon) !important;
}

.btn-outline-light:hover {
  transition: all 0.3s ease-in-out;
}

.btn-outline-light:hover {
  background: var(--bg-warning) !important;
  border-color: var(--bg-danger) !important;
}

.btn-light:hover {
  color: var(--bg-danger) !important;
  background-color: var(--bg-warning);
  border-color: var(--bg-danger);
}

.btn-check:checked+.btn-light,
:not(.btn-check)+.btn-light:active,
.btn-light:first-child:active,
.btn-light.active,
.btn-light.show {
  color: var(--bg-danger) !important;
  background-color: var(--bg-warning) !important;
  border-color: var(--bg-danger);
}

.text-shadow {
  text-shadow: 1px 1px 3px #000;
}

.bg-light-2 {
  background-color: #dedfe0 !important;
}

:focus,
.accordion-button:focus,
.form-control:focus,
.btn-outline-danger:focus,
.btn-check:focus+.btn-danger,
.btn-danger:focus,
.form-select:focus,
.btn-close:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 0%) !important;
}

:focus-visible {
  outline: none !important;
}

.form-select,
.form-control {
  min-height: 50px;

}

.bg-gradient {
  background: linear-gradient(120deg, #b51c364d, #cbcbcb4d) !important;
}

.form-control:focus {
  color: var(--bg-maroon);
  background-color: var(--bg-white);
  border-color: 0px;
  outline: 0;
  box-shadow: none !important;
}

.btn-primary:focus,
.btn-outline-primary:focus,
.btn-outline-danger:focus,
.btn-check:focus+.btn-danger,
.btn-danger:focus {
  color: var(--bg-white);
  background-color: var(--bg-maroon);
  border-color: 0px;
  box-shadow: 0 0 0 0.5rem rgb(225 83 97 / 0%);
}

.btn-primary:active:focus,
.btn-danger.active:focus,
.show>.btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.5rem rgb(225 83 97 / 0%) !important;
  background-color: var(--bg-maroon);
}

.outline-danger.active,
.btn-outline-danger.dropdown-toggle.show {
  color: var(--bg-white) !important;
  background-color: var(--bg-danger) !important;
  border-color: var(--bg-danger) !important;
}

a {
  text-decoration: none;
}

.mx-height-340 {
  max-height: 340px;
}

.img-trans {
  background: #1919198f;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.dropdown-item.active,
.dropdown-item.active,
.dropdown-item:active,
.dropdown-item:active .dropdown-item:active {
  background-color: var(--bg-danger) !important;
}

/* ------Utilities classes----- */

.rounded-10 {
  border-radius: 20px !important;
}

.rounded-5 {
  border-radius: 10px !important;
}

.ratio-1 {
  aspect-ratio: 1/1 !important;
}

.ratio-2 {
  aspect-ratio: 16/9;
}

.left-0 {
  left: 0 !important;
}

.mx-height-60vh {
  max-height: 60vh !important;
}

.w-220 {
  width: 220px !important;
}

.h-220 {
  height: 220px !important;
}

.w-180 {
  width: 180px !important;
}

.h-180 {
  height: 180px !important;
}

.h-1000 {
  height: 1000px !important;
}
.mxh-1000 {
  max-height: 1000px !important;
}

.h-fill {
  height: -webkit-fill-available !important;
}

.w-40 {
  width: 40px !important;
}

.height-20 {
  height: 20px !important;
}

.height-350 {
  height: 350px;
}

.h-min-content {
  height: min-content !important;
}

.width-20 {
  width: 20px !important;
}

.width-30 {
  width: 30px !important;
}

.width-60 {
  width: 60px !important;
}

.width-80 {
  width: 80px !important;
}

.w-120 {
  width: 120px !important;
}

.w-150 {
  width: 150px !important;
}

.h-150 {
  height: 150px !important;
}

/*/ .w-120 {
  width: 120px !important;
} /*/

.h-120 {
  height: 120px !important;
}

.height-80 {
  height: 80px !important;
}

.w-80 {
  width: 80px !important;
}

.h-80 {
  height: 80px !important;
}

.mx-width-300 {
  max-width: 300px;
}

.width-300 {
  width: 300px;
}

.mx-width-250 {
  max-width: 250px;
}

.mx-width-280 {
  max-width: 280px;
}

.width-280 {
  width: 280px;
}

.width-150 {
  width: 150px;
}

.width-180 {
  width: 180px;
}

.mx-width-400 {
  max-width: 400px;
}

.mx-height-250 {
  max-height: 400px;
}

.mx-width-600 {
  max-width: 600px !important;
}

.mx-width-800 {
  max-width: 800px;
}

.mx-width-850 {
  max-width: 850px;
}

.mx-width-900 {
  max-width: 900px;
}

.min-width-300 {
  min-width: 300px !important;
}

.min-w-220 {
  min-width: 220px !important;
}

.grid-center {
  display: grid !important;
  place-items: center !important;
}

.font-9-rem {
  font-size: 0.9rem !important;
}

.overflow-x-hidden {
  overflow-x: hidden !important;
}

.flex-1 {
  flex: 1 !important;
}

.flex-auto {
  flex: auto !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

/*/ .ngx-ic-cropper{
  border-radius: 100% !important;
} /*/
.heading-one {
  font-weight: 600 !important;
  letter-spacing: 2px;
  line-height: 30px;
  word-spacing: 2px;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

h6 {
  font-size: 1rem !important;
}

h5 {
  font-size: 1.4rem !important;
}

h4 {
  font-size: 1.6rem !important;
}

h3 {
  font-size: 1.8rem !important;
}

h2 {
  font-size: 2rem !important;
}

h1 {
  font-size: 2.2rem !important;
}

h2 {
  font-size: calc(1.325rem + 0.9vw) !important;
}

.btn-hide {
  opacity: 0 !important;
  /*/ display: none !important;/ */
  transition: all 0.5s ease-in-out !important;
}


.btn-show:hover .btn-hide {
  opacity: 1 !important;
  /*/ display: block !important; /*/
}

.head-p {
  font-size: 1.5rem !important;
  font-weight: 400;
  color: #252328;
  line-height: 2.3rem;
}

.lead {
  font-size: 1.2rem;
  font-weight: 400;
  color: #252328;
  line-height: 2.3rem;
}

.page-title {
  font-size: 3rem;
  color: var(--bg-white);
  margin-bottom: 0px;
  font-weight: 400;
  line-height: 1.2;
}

.bottom-0 {
  bottom: 0;
}

.min-width-40 {
  min-width: 40px !important;
}

.min-width-30 {
  min-width: 30px !important;
}

.min-width-80 {
  min-width: 80px !important;
}

.w-20-h-20 {
  height: 20px;
  width: 20px;
}

.w-25-h-25 {
  height: 25px !important;
  width: 25px !important;
}

.w-30-h-30 {
  height: 30px;
  width: 30px;
}

.w-35-h-35 {
  height: 35px;
  width: 35px;
}

.w-40-h-40 {
  height: 40px;
  width: 40px;
}

.w-50-h-50 {
  height: 50px;
  width: 50px;
}

.w-65-h-65 {
  height: 65px;
  width: 65px;
}

.w-85-h-85 {
  height: 85px;
  width: 85px;
}

.w-115-h-115 {
  height: 115px;
  width: 115px;
}

.w-180 {
  width: 180px;
}

.h-180 {
  height: 180px;
}

.h-220 {
  height: 220px;
}

.min-height-200 {
  min-height: 200px;
}

.image-cover {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.image-contain {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.cursor-pointer {
  cursor: pointer;
}

.z-index-1 {
  z-index: 1 !important;
}

.z-index-10 {
  z-index: 10 !important;
}

.z-index-60 {
  z-index: 60 !important;
}

.z-index-99 {
  z-index: 99 !important;
}

.z-index-999 {
  z-index: 999 !important;
}

.z-index-9999 {
  z-index: 9999 !important;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.center1 {
  /*/ display: flex;/ */
  align-items: center;
  justify-content: center;
}


.top-0 {
  top: 0 !important;
}

.top-70 {
  top: 91px !important;
}

.bottom-70 {
  bottom: 93px !important;
}

.top-90 {
  top: 90px !important;
}

.top-98 {
  top: 98px !important;
}

.right-0 {
  right: 0 !important;
}

.h-180 {
  height: 180px !important;
}

.mx-1200 {
  max-width: 1200px !important;
}

.p-sm {
  font-size: 0.8rem !important;
  font-weight: 400;
  line-height: 1rem;
}

.p-xsm {
  font-size: 0.7rem !important;
  font-weight: 400;
  line-height: 0.9rem;
}

.shadow-1 {
  transition: all 0.5s ease-in-out;
}

.shadow-1:hover {
  box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px,
    rgba(0, 0, 0, 0.05) 0px 5px 10px;
}

.shadow-2 {
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
    rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}

.truncate-2 {
  width: 100%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break: break-word;
}

.truncate-4 {
  width: 100%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  word-break: break-word;
}

.gap-10 {
  gap: 10px;
}

.gap-20 {
  gap: 20px;
}

.gap-30 {
  gap: 30px;
}

.gap-50 {
  gap: 50px;
}

.modal {
  z-index: 99999 !important;
}

.modal-backdrop.show {
  z-index: 999 !important;
}

.modal-content {
  z-index: 99999 !important;
}

/*/ .active {
  border-bottom: 3px solid var(--bg-danger) !important;
  color: var(--bg-danger) !important;
}/ */

.accordion-button:not(.collapsed) {
  color: var(--bg-danger) !important;
  background-color: var(--bg-warning) !important;
  border-color: var(var(--bg-danger)) !important;
  box-shadow: inset 0 -1px 0 rgb(0 0 0 / 50%);
}

.accordion-button::after {
  content: "\2193" !important;
  line-height: 2rem !important;
  text-align: center;
  width: 2rem;
  color: var(--bg-danger) !important;
  height: 2rem;
  border-radius: 50%;
  margin-left: auto;
  background-image: none !important;
  background: var(--bg-warning) !important;
  background-size: 2rem;
  transition: transform 0.3s ease-in-out;
}

.accordion-button:not(.collapsed)::after {
  content: "\2193" !important;
  line-height: 2rem !important;
  text-align: center;
  width: 2rem;
  color: var(--bg-warning) !important;
  height: 2rem;
  border-radius: 50%;
  margin-left: auto;
  background-image: none !important;
  background: var(--bg-danger) !important;
  background-size: 2rem;
  transition: transform 0.3s ease-in-out;
}

.list-danger li::marker {
  /* /content: "\2022";/ */
  /*/ font-size: 1.5rem;/ */
  color: var(--bg-danger);
}

.seperator-line.centered {
  margin-right: auto;
  margin-left: auto;
  background-color: var(--bg-danger);
}

.seperator-line {
  width: 15%;
  height: 4px;
  background-color: var(--bg-danger);
}

.transition-8 {
  transition: 0.8s ease-in-out;
}

.checkbox {
  accent-color: var(--bg-danger) !important;
  width: 16px;
  height: 16px;
}

.form-check-input:checked {
  background-color: var(--bg-danger) !important;
  border-color: var(--bg-danger) !important;
}

.form-switch .form-check-input {
  border-color: var(--bg-danger) !important;
}

.accent-color {
  accent-color: var(--bg-danger) !important;
}

.btn-outline-light:hover {
  color: var(--bg-danger) !important;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light {
  cursor: pointer;
  color: var(--bg-danger) !important;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.eye-btn {
  top: 50%;
  transform: translate(-50%, -50%) !important;
}

.c-btn {
  background-color: #f5f5f5 !important;
  border-radius: 0.25rem !important;
  font-size: 1rem !important;
  border: 1px solid #ced4da !important;
  min-height: 50px !important;
  color: #6c757c !important;
  font-weight: 500 !important;
}

.c-btn:focus,
.c-btn.active {
  border: 1px solid var(--bg-danger) !important;
}

.ng-select.ng-select-single .ng-select-container {
  height: 50px !important;
  background-color: #f5f5f5 !important;
  min-height: 36px !important;
  background-color: #f8f9fa !important;
}

.custom-ng-select.ng-select-single .ng-select-container {
  height: 36px !important;
  /*/ background-color: #f5f5f5 !important;
  min-height: 36px !important;
  background-color: #f8f9fa !important;/ */
}

.cdk-overlay-container {
  z-index: 99999 !important;
}


.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
  top: 0;
}

.ng-select .ng-select-container .ng-value-container .ng-input>input {
  height: 36px !important;
}

.ng-select.ng-select-focused:not(.ng-select-opened)>.ng-select-container {
  border-color: var(--bg-danger) !important;
  box-shadow: none !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-none {
  flex: none !important;
}

.hover-color {
  transition: all 0.3s ease-in-out !important;
}

.hover-color:hover {
  color: var(--bg-warning) !important;
}

.btn-danger {
  cursor: pointer;
  background: var(--bg-danger) !important;
  transition: all 0.3s ease-in-out;
  color: var(--bg-white) !important;
}

.btn-danger:hover {
  background: var(--bg-warning) !important;
  /* /border-color: #var(--bg-danger) !important;/ */
  color: var(--bg-danger) !important;
  transition: all 0.3s ease-in-out;
}

.btn-blue {
  cursor: pointer;
  background: var(--bg-blue) !important;
  transition: all 0.3s ease-in-out;
  color: var(--bg-white) !important;
}

.btn-blue:hover {
  background: var(--bg-danger) !important;
}

:hover.hover-scale,
:hover.hover-spacing {
  transition: all 0.3s linear !important;
}

:hover.hover-scale {
  transform: scale(1.1) !important;
  transition: all 0.5s ease-in-out !important;
}

:hover.hover-spacing {
  letter-spacing: 1px;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.fw-700 {
  font-weight: 500 !important;
}

/* /.btn-check:checked + .btn,
:not(.btn-check) + .btn:active,
.btn:first-child:active,
.btn.active,
.btn.show {
  color: var(--bg-white) !important;
} /*/

.btn-check:checked+.btn-outline-danger,
.btn-check:active+.btn-outline-danger,
.btn-outline-danger:active,
.btn-outline-danger.active,
.btn-outline-danger.dropdown-toggle.show {
  color: var(--bg-white) !important;
  background-color: var(--bg-danger);
  border-color: var(--bg-danger);
}

.btn-check:checked+.btn,
:not(.btn-check)+.btn:active,
.btn:first-child:active,
.btn.active,
.btn.show {
  color: var(--bg-white) !important;

}

.min-screen-height {
  min-height: calc(120vh - 100px) !important;
}

.offcanvas-bottom {
  height: auto !important;
}

.profile-upload-input {
  appearance: none !important;
  opacity: 0;
  display: none !important;
}

/* animations transition */

.slide-10 {
  transition: all 0.3s ease-in-out;
}

.slide-10:hover {
  transform: translateX(10px);
}

.up-2 {
  transition: all 0.3s ease-in-out !important;
}

.up-2:hover {
  transform: translateY(-2px) !important;
}

.text-hover-1 {
  color: var(--bg-danger);
}

.text-underline-hover {
  transition: all 0.5s ease-in-out !important;
}

.text-underline-hover:hover {
  text-decoration: underline !important;
}

.text-underline {
  text-decoration: underline !important;
}

.text-hover-1:hover {
  color: var(--bg-warning);
}

.hover-3 {
  color: var(--bg-danger);
  background: var(--bg-white);
  transition: all 0.5s linear !important;
}

.hover-3:hover {
  background: var(--bg-danger);
  color: var(--bg-white);
}

.flash {
  animation: flashFont 1s infinite linear;
}

@keyframes flashFont {
  0% {
    transform: scale(0.6);
  }

  50% {
    transform: scale(1);
  }

  100% {
    transform: scale(0.6);
  }
}

/* ----------------- */

.mat-accent .mat-slider-track-fill,
.mat-accent .mat-slider-thumb,
.mat-accent .mat-slider-thumb-label {
  background: var(--bg-danger);
}

/* --NGX Slider- */

.ngx-slider .ngx-slider-bubble {
  bottom: 10px !important;
  padding: 3px 6px !important;
  color: var(--bg-maroon) !important;
  font-size: 12px !important;
}

.ngx-slider .ngx-slider-pointer {
  width: 16px !important;
  height: 16px !important;
  top: -7px !important;
  background-color: var(--bg-danger) !important;
}

.ngx-slider .ngx-slider-pointer:after {
  content: "";
  width: 5px !important;
  height: 5px !important;
  top: 50% !important;
  transform: translate(-50%, -50%) !important;
  left: 50% !important;
  background: var(--bg-warning) !important;
}

.ngx-slider .ngx-slider-bar {
  background: var(--bg-blue) !important;
}

.ngx-slider .ngx-slider-selection {
  background: var(--bg-danger) !important;
}

/* -scrollbar- */

.scrollbar::-webkit-scrollbar {
  width: 6px !important;
  height: 6px !important;
  transition: all 0.3s ease-in-out !important;
}

.scrollbar::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px var(--bg-warning) !important;
  border-radius: 10px;
  width: 6px !important;
  height: 20px !important;
}

.scrollbar::-webkit-scrollbar-thumb {
  background: var(--bg-danger) !important;
  border-radius: 10px;
}

.scrollbar::-webkit-scrollbar-thumb:hover {
  background: var(--bg-warning) !important;
  border-radius: 10px;
}

@media (max-width: 576px) {
  .display-2 {
    font-size: 2rem !important;
  }

  html,
  body {
    font-size: 90% !important;
    line-height: 1.2 !important;
  }
}

.simple-notification-wrapper {
  max-width: 280px !important;
  width: 280px !important;
  top: 40%;
  right: 50%;
  margin-left: 0% !important;
  margin-right: 600px !important;
  /* /align-items: center !important;/ */
}

.simple-notification.info {
  background: #FFEBC1 !important;
  height: 100px !important;
  width: 250px !important;
  bottom: -46%;
  right: 200%;


}

.simple-notification div {
  margin-top: 20px !important;

}

.simple-notification.warn {
  background: var(--bg-warning) !important;
}

.simple-notification.has-icon .sn-content,
.simple-notification.has-icon .sn-html,
.simple-notification.has-icon .sn-title {
  padding: 0 20px 0 0 !important;
}

/* angular Datepicker */

.mat-form-field-appearance-fill .mat-form-field-infix {
  padding: 0 !important;
}

.mat-form-field-infix {
  display: flex !important;
}

.mat-form-field-infix {
  padding: 0 !important;
  border-top: none !important;
}

.mat-form-field-appearance-fill .mat-form-field-flex {
  border: 1px solid var(--bg-danger);
  border-radius: .2rem !important;
  padding: .2rem .8rem !important;
}

.mat-form-field-underline {
  display: none !important;
}

.mat-paginator-range-label {
  display: none !important;
}

/* crousel btn  */
.carousel-inner {
  position: relative;
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute !important;
  width: 30px !important;
  height: 30px !important;
  background-color: var(--bg-danger) !important;
}

/* img loader  from IGT */

.progressive-image {
  display: contents !important;
}

#loaderProgressive {
  /* / border: 2px solid #448bf7; / */
  border: 2px solid #2d6fc6;
  border-radius: 50%;
  border-top: 2px solid #dddfdd;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

.centerProgressive {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

/* / Content-Loading css / */

.comment-loader {
  height: 20px;
  margin-left: 13px;
}

.sg-left-loader {
  height: 120px !important;
  border-radius: 10px;
}

.sg-right-top-loader {
  height: 55px !important;
  border-radius: 10px;
}

.sg-right-bottom-loader {
  height: 55px !important;
  border-radius: 10px;
}

.sub-rect-event {
  width: 50px !important;
}

.sub-rect {
  border-radius: 100%;
  width: 50px;
  height: 50px;
  float: left;
}

.sub-rect-news {
  border-radius: 0.25rem;
  width: 30px;
  height: 2vh;
  float: left;
}

.sub-rect-user-follow {
  border-radius: 0.25rem;
  width: 70px;
  height: 25px;
  float: left;
}

.sub-rect-connection {
  border-radius: 100%;
  width: 30px;
  height: 30px;
  float: left;
}

.sub-rect-user-list {
  border-radius: 10px;
  width: 50px;
  height: 50px;
  float: left;
}

.pure-background {
  background-color: #e6e6e6;
}

.first-loader-name {
  height: 2vh;
  max-width: 150px;
  margin-left: 13px;
}

.first-loader-name-news {
  height: 2vh;
  max-width: 100%;
  margin-left: 13px;
}

.first-loader-content {
  height: 50vh;
}

.first-loader-ad {
  /* / height: 20vh; / */
  height: 100%;
}

.first-loader-profile {
  height: 100%;
}

.first-loader-bottom-line {
  height: 2vh;
  max-width: 100px;
}

.btn-divide-left {
  top: 0;
  left: 25%;
  height: 100%;
  /* / width: 5%; / */
}

.main-item {
  padding: 10px;
  background-color: #fff;
  width: 700px;
}

.sub-rect-sports-exp {
  border-radius: 25% !important;
}

/*/ .background-masker {
    background-color: #fff;
    position: absolute;
  }/ */



@keyframes placeHolderShimmer {

  0% {
    background-position: -800px 0
  }

  100% {
    background-position: 800px 0
  }

  /* /0% {
        background-color: rgba(165, 165, 165, 0.1);
    }

    50% {
        background-color: rgba(165, 165, 165, 0.3);
    }

    100% {
        background-color: rgba(165, 165, 165, 0.1);
    }/ */
}

@-webkit-keyframes placeHolderShimmer {

  0% {
    background-position: -800px 0
  }

  100% {
    background-position: 800px 0
  }

  /*/ 0% {
        background-color: rgba(165, 165, 165, 0.1);
    }

    50% {
        background-color: rgba(165, 165, 165, 0.3);
    }

    100% {
        background-color: rgba(165, 165, 165, 0.1);
    }/ */
}

.animated-background {
  animation-duration: 4s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background-color: #f6f7f8;
  background: linear-gradient(to right, #cdc4c41c 10%, #d2d2d24f 50%, #e5e5e563 80%);
  background-size: 800px 104px;
  position: relative;

  /* /animation: placeHolderShimmer 3s infinite ease-in-out;
    -webkit-animation: placeHolderShimmer 3s infinite ease-in-out;/ */
}

.animated-background-name {
  animation-duration: 4s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background-color: #f6f7f8;
  background: linear-gradient(to right, #c1c0c0 10%, #878484e3 50%, #e6e6e6 80%);
  background-size: 800px 104px;
  position: relative;
  border-radius: 8px;
  /*/ animation: placeHolderShimmer 3s infinite ease-in-out;
    -webkit-animation: placeHolderShimmer 3s infinite ease-in-out; /*/
}

.static-background {
  background-color: #f6f7f8;
  background-size: 800px 104px;
  height: 70px;
  position: relative;
  margin-bottom: 20px;
}

/* /.shared-dom {
    width: 800px;
    height: 110px;
  }/ */


/* .css-dom:empty {
    height: 220px;
    border-radius: 6px;
    box-shadow: 0 10px 45px rgba(0, 0, 0, .2);
    background-repeat: no-repeat;
    
    background-image:
      radial-gradient(circle 16px, lightgray 99%, transparent 0),
      linear-gradient(lightgray, lightgray),
      linear-gradient(lightgray, lightgray),
      linear-gradient(lightgray, lightgray),
      linear-gradient(lightgray, lightgray),
      linear-gradient(#fff, #fff); 
    
    background-size:
      32px 32px,
      200px 32px,
      180px 32px,
      230px 16px,
      100% 40px,
      280px 100%;
    
    background-position:
      24px 30px,
      66px 30px,
      24px 90px,
      24px 142px,
      0 180px,
      0 0;
  } /*/
/* / End Content-Loading css / */


.searchButton {
  width: 25px;
  height: 25px;
  font-size: 16px;
  margin-right: 35px;
  color: #696969;
}
.border-solid-1{
  border: solid 1.5px #bbbbbb!important;
}

/* progressive img */
/* / Content-Loading css / */

.comment-loader {
  height: 20px;
  margin-left: 13px;
}

.sg-left-loader {
  height: 120px !important;
  border-radius: 10px;
}

.sg-right-top-loader {
  height: 55px !important;
  border-radius: 10px;
}

.sg-right-bottom-loader {
  height: 55px !important;
  border-radius: 10px;
}

.sub-rect-event {
  width: 50px !important;
}

.sub-rect {
  border-radius: 100%;
  width: 50px;
  height: 50px;
  float: left;
}

.sub-rect-news {
  border-radius: 0.25rem;
  width: 30px;
  height: 2vh;
  float: left;
}

.sub-rect-user-follow {
  border-radius: 0.25rem;
  width: 70px;
  height: 25px;
  float: left;
}

.sub-rect-connection {
  border-radius: 100%;
  width: 30px;
  height: 30px;
  float: left;
}

.sub-rect-user-list {
  border-radius: 10px;
  width: 50px;
  height: 50px;
  float: left;
}

.pure-background {
  background-color: #e6e6e6;
}

.first-loader-name {
  height: 2vh;
  max-width: 150px;
  margin-left: 13px;
}

.first-loader-name-news {
  height: 2vh;
  max-width: 100%;
  margin-left: 13px;
}

.first-loader-content {
  height: 50vh;
}

.first-loader-ad {
  /* / height: 20vh; / */
  height: 100%;
}

.first-loader-profile {
  height: 100%;
}

.first-loader-bottom-line {
  height: 2vh;
  max-width: 100px;
}

.btn-divide-left {
  top: 0;
  left: 25%;
  height: 100%;
  /* / width: 5%; / */
}

.main-item {
  padding: 10px;
  background-color: #fff;
  width: 700px;
}

.sub-rect-sports-exp {
  border-radius: 25% !important;
}

/*/ .background-masker {
    background-color: #fff;
    position: absolute;
  }/ */

.bg-green {
  background: green !important;
}

@keyframes placeHolderShimmer {

  0% {
    background-position: -800px 0
  }

  100% {
    background-position: 800px 0
  }

  /*/ 0% {
        background-color: rgba(165, 165, 165, 0.1);
    }

    50% {
        background-color: rgba(165, 165, 165, 0.3);
    }

    100% {
        background-color: rgba(165, 165, 165, 0.1);
    } /*/
}

@-webkit-keyframes placeHolderShimmer {

  0% {
    background-position: -800px 0
  }

  100% {
    background-position: 800px 0
  }

  /* /0% {
        background-color: rgba(165, 165, 165, 0.1);
    }

    50% {
        background-color: rgba(165, 165, 165, 0.3);
    }

    100% {
        background-color: rgba(165, 165, 165, 0.1);
    }/ */
}

.animated-background {
  animation-duration: 4s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background-color: #f6f7f8;
  background: linear-gradient(to right, #cdc4c41c 10%, #d2d2d24f 50%, #e5e5e563 80%);
  background-size: 800px 104px;
  position: relative;

  /*/ animation: placeHolderShimmer 3s infinite ease-in-out;
    -webkit-animation: placeHolderShimmer 3s infinite ease-in-out; /*/
}

.animated-background-name {
  animation-duration: 4s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background-color: #f6f7f8;
  background: linear-gradient(to right, #c1c0c0 10%, #878484e3 50%, #e6e6e6 80%);
  background-size: 800px 104px;
  position: relative;
  border-radius: 8px;
  /*/ animation: placeHolderShimmer 3s infinite ease-in-out;
    -webkit-animation: placeHolderShimmer 3s infinite ease-in-out;/ */
}

.static-background {
  background-color: #f6f7f8;
  background-size: 800px 104px;
  height: 70px;
  position: relative;
  margin-bottom: 20px;
}

.min_content_height_119 {
  min-height: calc(100vh - 199px);
}

.min_content_height_175 {
  min-height: calc(100vh - 175px);
}

.min_content_height_178 {
  min-height: calc(100vh - 178px);
}

.min_content_height_202 {
  min-height: calc(100vh - 202px);
}

.min_content_height_246 {
  min-height: calc(100vh - 246px);
}

.min_content_height_271 {
  min-height: calc(100vh - 271px);
}

.mt-68 {
  margin-top: 68px !important;
}

.mt-93 {
  margin-top: 93px !important;
}

.mt-120 {
  margin-top: 120px !important;
}

.mt-136 {
  margin-top: 136px !important;
}


/* /.shared-dom {
    width: 800px;
    height: 110px;
  } /*/


/*/ .css-dom:empty {
    height: 220px;
    border-radius: 6px;
    box-shadow: 0 10px 45px rgba(0, 0, 0, .2);
    background-repeat: no-repeat;
    
    background-image:
      radial-gradient(circle 16px, lightgray 99%, transparent 0),
      linear-gradient(lightgray, lightgray),
      linear-gradient(lightgray, lightgray),
      linear-gradient(lightgray, lightgray),
      linear-gradient(lightgray, lightgray),
      linear-gradient(#fff, #fff); 
    
    background-size:
      32px 32px,
      200px 32px,
      180px 32px,
      230px 16px,
      100% 40px,
      280px 100%;
    
    background-position:
      24px 30px,
      66px 30px,
      24px 90px,
      24px 142px,
      0 180px,
      0 0;
  }/ */
/* / End Content-Loading css / */

.progressive-image {
  display: contents !important;
}

.placeholderTwoOrFour {
  height: 100%;
  min-height: 200px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* / background-color: #dddfdd; / */
  background-color: #dddfdd;
  opacity: 0.5;
}

#loaderProgressive {
  /* / border: 2px solid #448bf7; / */
  border: 2px solid #2d6fc6;
  border-radius: 50%;
  border-top: 2px solid #dddfdd;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}
.white-cross {
  background-color: #811719 !important;
  border-color: #811719 !important;
  color: #ffffff;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

.centerProgressive {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.placeholderOneOrThree {
  height: 100%;
  min-height: 400px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* / background-color: #dddfdd; / */
  background-color: #dddfdd;
  opacity: 0.5;
}

.toast-center-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.user_email_verified {
  /*/ display: none;/*/
  display: none;
}

.user_email_verified1 {
  display: inline;
}

a.notClickable {
  pointer-events: none;
  cursor: default;
}

/* CSS to change text color to white on hover for new tab*/
.newTab-card123 {
  color: var(--bg-danger);
  background-color: var(--bg-white);
}

.newTab-card123:hover {
  color: var(--bg-white);
  background-color: var(--bg-danger);
  border-color: var(--bg-danger);
}



/* Home-login */


@media screen and (max-width: 768px) {
  .vid-mt {
    padding-top: 12%;
  }
}

@media screen and (max-width: 425px) {
  .vid-mt {
    padding-top: 20%;
  }
}

@media screen and (max-width: 375px) {
  .vid-mt {
    padding-top: 33%;
  }

  .mt-68 {
    margin-top: 0px !important;
  }
}

@media screen and (max-width: 320px) {
  .vid-mt {
    padding-top: 44%;
  }

}

.image-fixed {
  object-fit: contain;
}

.vh {
    /* min-height: calc(120 vh - 100px) !important; */
    height: calc(100vh - 138px) !important;

 }
.no-cursor-pointer {
  cursor: unset !important;
}
/* Home-logout */

/*/ @media screen and (max-width: 768px){
  .banner {
    padding-top: 12%;
}
} /*/

@media screen and (max-width: 425px) {
  .vid-home-mt {
    padding-top: 11%;
  }
}

/*/ @media screen and (max-width: 375px){
  .vid-mt {
    padding-top: 33%;
}
} /*/

/*/ @media screen and (max-width: 320px){
  .vid-mt {
    padding-top: 46%;
}
}/ */